<template>
  <CustomLoader v-if="pageLoading" height="500px" />

  <div class="company-info-setup py-20" v-else>
    <div class="row col-12 mx-0">
      <div class="col-md-4 ps-0">
        <h2>{{ $t("Get started") }}</h2>
        <p>{{ $t("By entering your company details") }}</p>
      </div>
      <div class="col-md-4 text-center">
        <img class="w-50" src="/media/logos/buying/blue-logo-buying-teams.svg" alt="">
      </div>
      <div class="col-md-4 d-flex justify-content-end align-items-center">
        <p class="mb-0 me-3">{{ $t("Existing User ?") }}</p>
        <router-link to="/sign-in">{{ $t("Log in") }}</router-link>
      </div>
    </div>

    <el-form
      class="setupForm"
      ref="companySetupFormRef"
      :model="companySetupForm"
      :rules="companySetupFormRules"
    >
      <el-form-item prop="company_name">
        <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Group Company Name") }}</label>
        <el-input
          :placeholder="$t('Enter your Company Name (Group level)')"
          v-model="companySetupForm.company_name"></el-input>
      </el-form-item>
      <el-form-item prop="industries">
        <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Industry") }}</label>
        <el-select
          multiple
          v-model="companySetupForm.industries"
          :placeholder="$t('Select Industry')">
          <el-option
            v-for="(industry, index) in industries"
            :key="index"
            :label="industry"
            :value="industry"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="num_employees">
        <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Number of Employees") }}</label>
        <el-select
          v-model="companySetupForm.num_employees"
          :placeholder="$t('Select Number of Employees')">
          <el-option
            v-for="(employeesNumber, index) in employeesNumbers"
            :key="index"
            :label="employeesNumber"
            :value="employeesNumber"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="revenue">
        <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Global revenue") }}</label>
        <el-select
          v-model="companySetupForm.revenue"
          :placeholder="$t('Select Global revenue')">
          <el-option
            v-for="(globalRevenue, index) in globalRevenues"
            :key="index"
            :label="globalRevenue.label"
            :value="globalRevenue.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="country">
        <label class="form-label auth-label fs-6 fw-bolder required">{{ $t("Country of Global headquarter") }}</label>
        <el-select
          filterable
          v-model="companySetupForm.country"
          :placeholder="$t('For eg. `Denmark`')">
          <el-option
            v-for="(country, index) in countries"
            :key="index"
            :label="country"
            :value="country"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <button
      :data-kt-indicator="buttonIndicator" :disabled="buttonIndicator === 'on'"
      id="kt_sign_in_submit"
      class="btn btn-lg main-btn w-100 my-5 mt-10 d-block m-auto"
      @click="saveToContinue()">
      <span class="indicator-label"> {{ $t("continue") }} </span>

      <span class="indicator-progress">
        {{ $t("pleaseWait") }}
        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
      </span>
    </button>

    <div class="d-flex justify-content-center align-items-center links-wrapper">
      <a :href="platformConfigs.platformLinks.Terms" target="_blank">
        {{ $t("Terms & Conditions") }}
      </a>
      <span></span>
      <a :href="platformConfigs.platformLinks.Privacy" target="_blank">
        {{ $t("Privacy Policy") }}
      </a>
    </div>

  </div>
</template>

<script>
import store from "@/store";
import vClickOutside from "click-outside-vue3";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { defineComponent } from "vue";
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
import { StaticDataEnum } from "@/store/enums/StaticDataEnum";
import { PlatformConfigsModel } from "@/store/models/shared/config/PlatformConfigsModel";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";

export default defineComponent({
  name: "InvBusinessCompanySetup",
  components: {CustomLoader},
  directives: {
    clickOutside: vClickOutside.directive
  },

  data() {
    return {
      companySetupForm: {
        company_name: "",
        industries: [],
        num_employees: "",
        revenue: "",
        country: ""
      },
      companySetupFormRules: {
        company_name: [
          { required: true, message: this.$t("Please input Group Company Name"), trigger: "change" },
          { min: 1, max: 60, message: this.$t("Length should be 1 to 60"), trigger: "change" }
        ],
        industries: [
          { required: true, message: this.$t("Please select Industry"), trigger: "change" }
        ],
        num_employees: [
          { required: true, message: this.$t("Please select Number of Employees"), trigger: "change" }
        ],
        revenue: [
          { required: true, message: this.$t("Please select Global revenue"), trigger: "change" }
        ],
        country: [
          { required: true, message: this.$t("Please input Country of Global headquarter"), trigger: "change" }
        ]
      },
      showDropdown: false,
      buttonIndicator: 'off',
      pageLoading: true,
        platformConfigs: new PlatformConfigsModel(PlatformTypeEnum.BUSINESS)
    };
  },

  mounted() {
    this.getStaticData();
  },

  computed: {
    industries() {
      return store.getters.allIndustries;
    },
    employeesNumbers() {
      return store.getters.employeesCounts;
    },
    globalRevenues() {
      return store.getters.globalRevenues;
    },
    countries() {
      return store.getters.allCountries;
    }
  },

  methods: {
    getStaticData() {
      this.pageLoading = true;
      return Promise.all([
        store.dispatch('fetchStaticData', StaticDataEnum.COUNTRIES),
        store.dispatch('fetchStaticData', StaticDataEnum.INDUSTRIES),
        store.dispatch('fetchStaticData', StaticDataEnum.EMPLOYEE_COUNTS),
        store.dispatch('fetchStaticData', StaticDataEnum.REVENUES),
      ]).finally(() => this.pageLoading = false);
    },

    onClickOutside() {
      this.showDropdown = false;
    },

    async saveToContinue() {
      this.$refs.companySetupFormRef.validate((valid) => {
        if (valid) {
          this.buttonIndicator = 'on';

          store.dispatch("updateInvBusiness", this.companySetupForm)
            .then(res => {
              if (res.status === 200) {
                redirectToRouteWithName('business-register/ext-personal-info');
              }
            })
            .finally(_ => {
              this.buttonIndicator = 'off';
            });
        }
      });
    }
  }
});
</script>
<style lang="scss">
.company-info-setup {
  .dropdown-list {
    background: #FFFFFF;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    width: auto;
    padding: 20px;
    display: inline-block;
    position: absolute;
    margin-top: 5px;
    left: 20%;

    input {
      position: absolute;
      width: 90px;
      opacity: 0;
      z-index: 9;
      cursor: pointer;
    }

    &::after {
      content: '';
      width: 10px;
      height: 10px;
      border-bottom: 8px solid #fff;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      position: absolute;
      top: - 8px;
      left: calc(50% - 4px);
    }

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border-bottom: 8px solid #bfc4e357;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      position: absolute;
      top: - 9px;
      left: calc(50% - 4px);
    }
  }

  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    color: #000000;
  }

  p {
    font-size: 16px;
    line-height: 29px;
    color: #434343;
    opacity: 0.6
  }

  .uploadFile {
    background: #F4F5FE;
    border: 1px solid rgba(67, 91, 244, 0.3);
    box-sizing: border-box;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;

    h4 {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #435BF4;
      margin-left: 20px;
      margin-bottom: 0;
    }
  }
  .setupForm {
    .el-form-item .el-input .el-input__inner {
      border: 1px solid #fff;
    }
    .el-form-item.is-error .el-input__inner {
      border-color: #f56c6c;
    }
  }
  .links-wrapper {
    margin-top: 28px;
    a {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #435BF4;
    }
    span {
      display: block;
      width: 1px;
      height: 50px;
      background-color: rgba(0, 0, 0, 0.1);
      margin: 0 20px;
    }
  }
}
</style>
